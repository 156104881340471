.side-menus {
  flex: 1;
  overflow:auto;
  font-weight: 600;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0 !important
  }
  * {
    user-select: none;
  }
}

.side-footer {
  width: 100%;
  button {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      height: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .download-btn {
    width: 100%;
    background: rgb(239,111,56);
    color: #fff;
    &:hover {
      background: rgba(239,111,56,.6) !important;
      color: #fff !important;
    }
  }
}
