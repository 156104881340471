.progress-circle {
  width: 90px;
  height: 90px;
  position: absolute;
  transition: opacity 0.3s;
  background: rgba(0,0,0,.7);
  border-radius: 45px;
  color: #fff;
  >div {
    width: 90px !important;
    height: 90px !important;
  }
}
.avatar-mask {
  width: 90px;
  height: 90px;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 0.3s;
  font-size:20px;
  color:#fff;
  cursor: pointer;
  border-radius: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:1;
  &:hover {
    opacity: 1 !important;
  }
  button {
    background: rgba(0, 0, 0, 0);
    border: none;
    color: #fff;
    &:hover {
      background: rgba(0, 0, 0, 0) !important;
    }
  }
}
