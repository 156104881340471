.logo-col {
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 50px;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 120px;
    }
  }
}

.header-btn-group {
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: right;
  button {
    border: none;
    box-shadow: 0 0 0 0;
  }
  .ant-space-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
