.header-row {
  justify-content: center;
  --search-space-width:95%;
  width: 100%;
  margin: 2px 0 0 2px;
  border-radius: 10px;
  background: linear-gradient(to bottom right, #ef6f38, #f7b497);
  min-height: 80px;
  height: 216px;
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 150px;
  .title-row {
    width: 95%;
    height: 64px;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    column-gap: 15px;
    >span:first-child {
      line-height: 64px;
      flex: 1;
      font-size: xx-large;
      font-weight: 700;
      color: #fff;
    }
  }
}

.content-row {
  animation-name: slide-table;
  animation-duration: 0.75s;
  position: absolute;
  background: #fff;
  border-radius: 10px;
  box-shadow: 1px 1px 14px 0 rgba(0,0,0,0.14);
  width: 95%;
  height: calc(100% - 95px);
  top: 79px;
  left: 50%;
  transform: translate(-50%);
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  >*{
    width: 100%;
  }
  .search-list {
    align-items: center;
    column-gap: 10px;
    display: flex;
    height: 40px;
    justify-content: flex-end;
    >*{
      height: 100%;
    }
    .ant-input-wrapper,.ant-input-affix-wrapper{
      height: 100%;
    }
  }
  .page-tabs-row,.table-tabs-row {
    height: 40px;
    >*{
      height: 100%;
    }
  }
  .filter-show-row{
    align-items: center;
    height: 30px;
    overflow-x: auto;
    flex-wrap: nowrap;
  }
  .data-row {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.filter-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  label {
    font-size: 16px;
  }
  .filter-row{
    display:flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    width: 100%;
    //>*:not(label){
    //  height: 40px;
    //}
  }
}
.ant-input-affix-wrapper{
  height: 40px !important;
}

@keyframes slide-table {
  from {
    top: 650px;
  }
  to {
    top: 79px;
  }
}
