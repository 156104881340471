.tox-tinymce {
  border-radius: 0 !important;
  border: none !important;
  border-top: 2px solid #eee !important;
}

.tox .tox-edit-area::before {
  border-color: rgb(239,111,56) !important;
  border-radius: 0 !important;
}

[aria-label="send message"] {
  background: rgb(239,111,56) !important;
  color: #fff !important;
}

.tox-toolbar__primary,.tox-toolbar__group {
  width: 100% !important;
}

//.tox-toolbar__group {
//  justify-content: right !important;
//}
