.login-container {
  width: 100%;
  height: 100%;
  >div{
    width: 50%;
    height: 100%;
  }
  .left-advertising {
    background: linear-gradient(to top right, #ef6f38, #f7b497);
    .word-row{
      width: calc(100% - 242px);
      font-family: 'fangsong';
      .dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #fff;
      }
      h2 {
        margin: 0;
      }
    }
  }
  .right-login {
    background: #fff;
    .login-content {
      width: 100%;
      height: calc(100% - 140px);
      .login-form {
        width: 100%;
        .user-input {
          height: 50px;
        }
        .login-btn {
          width: 100%;
          height: 50px;
          background: #63d295;
          color: #fff;
          font-size: 16px;
          font-weight: 700;
          &:hover {
            border-color: #63d295;
          }
        }
      }
    }
    .login-footer {
      width: 100%;
      .icon-footer {
        width: 100%;
        img {
          width: 25px;
          height: 25px;
        }
      }
      .login-copyright{
        text-align: center;
        color: #b4b1b1;
      }
    }
  }
}
