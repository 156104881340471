.order-detail-title {
  border-bottom: 1px solid rgba(205, 202, 202, 0.5);
  padding-bottom: 10px;
  .wrap-span{
    width: 100%;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  >div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    >span:first-child {
      font-size: 17px;
    }
  }
}

.order-detail-content {
  padding: 10px;
  overflow:auto;
  >div {
    padding: 15px;
    box-shadow: 0 1px 14px 0 rgba(0,0,0,.14);
  }
}

.store-detail-card {
  .ant-table-filter-trigger {
    color: rgb(239,111,56);
    &:hover {
      background: rgb(239,111,56);
      color: #fff;
    }
  }
  .store-detail-table{
    thead {
      th:nth-child(2) {
        border-top-right-radius: 8px !important;
        &::before {
          width: 0 !important;
        }
      }
      th:nth-child(3){
        &::before{
          width: 0 !important;
        }
      }
      th:nth-child(4){
        border-top-left-radius: 8px !important;
      }
    }
  }
  .split-th {
    background: #fff !important;
    padding: 0 !important;
    width: 15px !important;
    border: none !important;
  }
}

.after-sales-detail {
  .ant-tabs-tab-active {
    background: rgb(239,111,56) !important;
    .ant-tabs-tab-btn {
      color: #fff !important;
    }
  }
  .message-box-left,.message-box-right {
    padding: 10px 15px;
  }
  .message-box-left {
    justify-content: left;
    .content-box {//首先准备一个div
      display: flex;
      flex-direction: column;
      word-break: break-word;
      max-width: 55%;
      background: rgb(0, 0, 0);
      border-radius: 6px;
      padding: 5px;
      color: #fff;
    }
    .content-box-before {  //这里就是小三角了
      margin-top: 5px;
      border-width: 7px;
      border-style: dashed  solid  dashed  dashed;//四个参数分别代表向下，左，上右的小三角(对应方向的小三角设置为solid，其他的为dashed。我这里演示向左的小三角，所以设置第二个）
      border-color:transparent rgb(0,0,0) transparent transparent;//四个参数分别代表向下，左，上右的小三角。对应方向的小三角设置你想要的颜色（为了直观我这里设置黑色），其他的为transparent。我这里演示向左的小三角，所以设置第二个。
    }
    .message-avatar {
      margin-top: 10px;
    }
    .message-uname {
      // margin-left: 15px;
    }
  }

  .content-spin-box {
    .ant-spin-dot-item {
      background: #fff;
    }
  }

  .message-box-right {
    .content-box {//首先准备一个div
      display: flex;
      flex-direction: column;
      word-break: break-word;
      max-width: 55%;
      background: rgb(239,111,56);
      border-radius: 6px;
      padding: 5px;
      color: #fff;
    }
    .content-box-before {  //这里就是小三角了
      margin-top: 5px;
      border-width: 7px;
      border-style: dashed  solid  dashed  dashed;//四个参数分别代表向下，左，上右的小三角(对应方向的小三角设置为solid，其他的为dashed。我这里演示向左的小三角，所以设置第二个）
      border-color:transparent transparent transparent rgb(239,111,56);//四个参数分别代表向下，左，上右的小三角。对应方向的小三角设置你想要的颜色（为了直观我这里设置黑色），其他的为transparent。我这里演示向左的小三角，所以设置第二个。
    }
    .message-avatar {
      margin-top: 10px;
    }
    .message-uname {
      margin-right: 15px;
    }
  }
  .after-sales-detail-toolbar {
    padding-left:5px;
    position:relative;
    width:calc(100% - 46px);
    bottom:191px;
    z-index:9;
    float:right;
    margin-right:5px;
    .after-sales-detail-upload {
      display:flex;
      justify-content:center;
      align-items:center;
      border:none;
      width:34px;
      height:28px;
      padding:0;
      border-radius: 3px;
      box-shadow: none;
      &:hover{
        background: #f0f0f0;
      }
    }
  }
  ul,ol {
    margin: 0;
    padding: 0;
    list-style-position: inside;
  }
  p {
    margin: 0;
  }
}

.tool-box {
  position: absolute;
  border-radius: 10px;
  width: 50%;
  left: 25%;
  margin: auto;
  background: #fff;
  padding: 15px;
  z-index: 2;
  font-size: 16px;
  box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.14);
}

.ant-dropdown-menu {
  max-height: 200px !important;
  overflow: auto !important;
}
