.fbali-layout {
  .layout-header {
    padding: 0;
    background: white;
    box-shadow: 0 1px 12px 0 rgba(0,0,0,.14);
    z-index: 100;
  }
  .layout-side {
    z-index: 99;
    box-shadow: 1px 0 14px 0 rgba(0,0,0,.1);
    padding-bottom: 5px;
    padding-top: 3px;
    footer {
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      button {
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          height: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
  .layout-content {
    background: #F2F4F8;
    overflow: hidden;
    //-ms-overflow-style: none;
    //&::-webkit-scrollbar {
    //  width: 0 !important
    //}
  }
}
