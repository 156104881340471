.dashboard-container {
  width: 100%;
  height: 100%;
  padding: 10px;
  overflow: auto;
  h1, h2, h3, h4, h5 {
    margin: 0;
  }
  h1,h2,h3,h4,h5,span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .order-dot {
    width: 15px;
    height: 15px;
    background: rgb(239, 111, 56);
    border-radius: 50%;
  }
  .order-card {
    background: #fff;
    border-radius: 10px;
    width: 300px;
    height: 100%;
    box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.14);
    padding: 15px;
    .order-card-title {
      width: 100%;
    }
    .order-circle-container {
      width: 100%;
      height: calc((100% - 83px) / 2);
    }
  }
  .logistics-card {
    width: 100%;
    background:#fff;
    border-radius:10px;
    box-shadow:0 1px 14px 0 rgba(0,0,0,.14);
    padding: 15px;
    .logistics-card-title {
      width: 100%;
      .logistics-dot {
        width: 15px;
        height: 15px;
        background: #7182eb;
        border-radius: 50%;
      }
    }
  }
  .sale-data-card {
    padding: 15px;
    .sale-data-title {
      width: 100%;
      .sale-data-dot {
        width: 15px;
        height: 15px;
        background: #7182eb;
        border-radius: 50%;
      }
    }
  }
  .after-sale-table {
    max-width: calc((100% - 45px) / 4);
    height:100%;
    background:#fff;
    border-radius:10px;
    box-shadow:0 1px 14px 0 rgba(0,0,0,.14);
    padding: 15px;
    .after-sale-card-title {
      width: 100%;
      .after-sale-dot {
        width: 15px;
        height: 15px;
        background: #7182eb;
        border-radius: 50%;
      }
    }
  }
  .video-box{
    margin: 0;
    padding: 0;
    border-radius:10px;
  }
  .number-card {
    height: 100%;
    width:calc((100% - 45px) / 4);
    border-radius: 10px;
    box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.14);
  }
  .w100 {
    width: 100%;
  }
  .h100 {
    height: 100%;
  }
}
