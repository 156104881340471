*{
  box-sizing: border-box;
}

html {
  height: 100%;
  box-sizing: border-box;
  body {
    margin: 0;
    height: 100%;
    #root {
      height: 100%;
      .fbali-layout {
        height: 100%;
      }
    }
  }
}

.ant-menu-item-selected,.ant-menu-item-active {
  .store-icon {
    svg {
      path {
        fill: rgb(239, 111, 56);;
      }
    }
  }
}

.store-icon{
  svg {
    width: 14px;
    height: 14px;
  }
}

.ant-spin-nested-loading {
  width: 100%;
  height: 100%;
  .ant-spin-container {
    width: 100%;
    height: 100%;
  }
}

.orange-btn {
  background: rgb(255, 250, 232);
  border: 2px solid rgb(239, 111, 56);
  color: rgb(239, 111, 56);
  &:hover{
    background: rgb(255, 225, 214) !important;
  }
}

.purple-btn {
  background: rgb(200, 78, 255);
  border: none;
  color: #fff;
  &:hover {
    background: rgb(205, 104, 253) !important;
    color: #fff !important;
  }
}

.red-btn {
  background: rgba(255, 44, 81, 0.8);
  border: none;
  color: #fff;
  &:hover {
    background: rgba(253, 66, 101, 0.5) !important;
    color: #fff !important;
  }
}

.orange-fill-btn {
  background: rgb(255, 98, 13);
  color: #fff;
  border: none;
  &:hover {
    background: rgb(255, 121, 51) !important;
    color: #fff !important;
  }
}

* {
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  //轨道
  &::-webkit-scrollbar-track {
    background: rgba(239,111,56,0.1);
    border-radius: 999px;
  }
  //滑块
  &::-webkit-scrollbar-thumb {
    border-radius: 999px;
    background: rgb(239, 111, 56);
  }
}

.fulfill-order-btn {
  background: #ff9d17;
  border: #ff9d17;
  &:hover{
    background: #ff9d17 !important;
    color: black !important;
  }
}

.sourcing-btn {
  background: #3ae8b2;
  border: #3ae8b2;
  &:hover{
    background: #3ae8b2 !important;
    color: black !important;
  }
}

.top-up-btn {
  background: rgb(200, 78, 255);
  border: rgb(200, 78, 255);
  &:hover{
    background: rgb(200, 78, 255) !important;
    color: black !important;
  }
}

.canceled-btn {
  background: #b9b6b6;
  border: #b9b6b6;
  &:hover{
    background: #b9b6b6 !important;
    color: black !important;
  }
}

.ask-sourcing-btn {
  background: #7182eb;
  border: #7182eb;
  &:hover{
    background: #7182eb !important;
    color: black !important;
  }
}
